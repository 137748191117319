<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue"
import { Autoplay, EffectFade } from "swiper/modules"
import "swiper/css"
import "swiper/css/navigation"

useHead({
  title: "Home",
})

definePageMeta({
  auth: false,
  layout: "topnav",
})
const { status } = useAuth()

if (status.value === "authenticated") {
  navigateTo("/dashboard")
}
const slides = [
  {
    img: "/inatax/banner_slide_1.jpg",
  },
  {
    img: "/inatax/banner_slide_2.jpg",
  },
]
</script>
<template>
  <div class="w-full relative min-h-screen">
    <div class="absolute inset-0 text-center bg-black opacity-80 z-10"></div>
    <div class="absolute top-1/3 left-0 right-0 text-center z-10">
      <div class="flex justify-center items-center flex-col gap-6">
        <h1 class="text-5xl text-white font-black">Selamat Datang</h1>
        <h2 class="text-5xl text-white font-black">MONITAX V3</h2>
        <div class="mt-10">
          <ButtonLogin size="xl" />
        </div>
      </div>
    </div>
    <Swiper
      :loop="true"
      :effect="'fade'"
      :slidesPerView="1"
      :spaceBetween="30"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :modules="[Autoplay, EffectFade]"
      dir="ltr"
      key="false"
    >
      <swiper-slide v-for="slide in slides">
        <NuxtImg
          :src="slide.img"
          provider="imagekit"
          format="webp"
          class="bg-cover w-full z-1"
        />
      </swiper-slide>
    </Swiper>
  </div>
</template>

<style scoped></style>
